.events {
  .date {
    width: 20%;
    margin-right: 15px;
  }
  .event-info {
    width: 100%;
  }
  .event-item .wrapper {
    transition: all 0.3s;
    div.arrow {
      transition: all 0.3s;
    }
    &:hover {
      background: $yellow;
      color: $extradark;
      svg path {
        stroke: $extradark;
      }
      div.arrow {
        transform: translateX(-10px);
      }
    }
  }
}

.social-icon img {
  width: 40px;
}

a.link-block {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: transparent;
  text-shadow: none;
  text-indent: -9999px;
}

.row-session {
  background: $light;
  &:nth-child(even) {
    background: $white;
  }
}

.tab-pane {
  display: none;
  &.active {
    display: block;
  }
}

.nav-tabs.events {
  border-bottom: 0;
  .nav-link {
    transition: all 0.3s;
    border-color: $white;
    margin: 0 12px 0 0;
    color: $white;
    &:hover {
      border-color: $yellow;
      color: $yellow;

    }
    &.active {
      color: $extradark;
      background: $yellow;
      border-color: $yellow;
      border-bottom: 0;
    }
  }
}

.link-box-contain .stay-connected {
  overflow: hidden;
}
