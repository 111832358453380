// footer {
//   position: sticky;
//   bottom: 0;
//   z-index: -1;
// }

#pageFooter {
  background: $cream;
  border-bottom: 10px solid $orange;
  .footer-logo img {
    max-width: 150px;
    height: auto;
  }
  .footer-nav {
    .nav-item {
      font-size: 80%;
      a, #ot-sdk-btn.ot-sdk-show-settings {
        color: $extradark;
        margin: 0 1rem 0 0;
        padding: 0;
        &:hover {
          color: $orange;
        }
      }
      #ot-sdk-btn.ot-sdk-show-settings {
        border: none;
        background-color: transparent;
        font-size: 1em;
      }
    }
  }
  .twdc {
    height: 25px;
    margin-left:3px;
    img {
      height: 100%;
    }
  }
  .social-icons-contain {
    display: flex;
    .social-line {
      display: flex;
      align-items: center;
      margin: 0  1.3rem 0 0 !important;
      p {
        font-size: 80% !important;
        margin-right: 0.5rem;
        letter-spacing: 0;
      }
      .social-icon {
        width: 35px;
        height: 35px;
        background: rgba($extradark, 0.6);
        img {
          height: auto;
        }
        &:hover {
          background: $orange;
        }
      }
    }
  }
}

@media screen and (max-width: 1055px) {
  #pageFooter {
    .social-icons-contain {
      .social-line {
        flex-direction: column;
        align-items: flex-start;
        margin-right: 2.5rem;
      }
    }
    .footer-nav .nav-item a, .footer-nav .nav-item #ot-sdk-btn.ot-sdk-show-settings {
      padding: 0.3rem 1rem 0 0;
    }
  }
}

@media screen and (max-width: $screen-md-min) {
  #pageFooter {
    .copyright {
      order: 2;
      margin-top: 1rem;
    }

  }
}

@media screen and (max-width: 665px) {
  #pageFooter {
    .footer-logo {
      margin-bottom: 2rem;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      img {
        max-width: 200px;
      }
    }
    .footer-social {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 1.5rem;
    }
  }
}

@media screen and (max-width: $screen-sm-min) {
  #pageFooter {
    .social-icons-contain {
      flex-wrap: wrap;
      .social-line {
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 2rem !important;
        .social-icon {
          width: 50px;
          height: 50px;
        }
      }
  }
}
}
