.py-6 {
  padding: 6rem 15px !important;
}
@media screen and (min-width: $screen-xl-min) {
  h1 {
    font-size: calc(48px + 16 * ((100vw - 320px) / 680)) !important;
  }

  h2 {
    font-size: calc(32px + 6 * ((100vw - 320px) / 680)) !important;
    margin-bottom: 1.3rem;
  }

  h3 {
    font-size: calc(24px + 6 * ((100vw - 320px) / 680)) !important;
  }

  h5 {
    font-size: calc(14px + 6 * ((100vw - 480px) / 680)) !important;
  }

  p {
    font-size: calc(10px + 4 * ((100vw - 320px) / 680)) !important;
    max-width: 700px;
  }

  .link-box {
    .text-move {
      // left: 3rem;
      // padding-right: 6rem !important;
    }
  }

  .question-body {
    margin-top: 0.7em !important;
  }

  .sketch-bg-contain {
    padding-top: 10em !important;
    padding-bottom: 10em !important;
  }

  .color-box figcaption {
    .btn {
      margin-top: 3%;
    }
  }
}
