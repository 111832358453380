

.all-corners {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header-line {
  width: 70px;
  height: 3px;
  margin-bottom: 20px;
  display: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  transform-origin: left;
}

.cursor-default {
  cursor: default !important;
}

.link-box {
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  background: $extradark;
  max-height: 500px;
  // transform: translate3d(0,0,0);
  a.link-overlay {
    line-height: 0;
    font-size: 0;
    color: transparent;
    top: 0;
    left: 0;
  }

  &.link-box.col-lg-4::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  &.shortcircuit::before {
    padding-top: 47.083%;
  }

  figcaption {
    justify-content: flex-end;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    overflow: hidden;
    @include linear-gradient(32deg, rgba($extradark, 0.66) 0%, rgba($extradark, 0.15) 60%, rgba($extradark, 0) 100%);
    &::before {
      width: 100%;
      height: 110%;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: $extradark;
      transform: scaleY(0);
      transform-origin: bottom;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    &.hover-orange::before {
      background: linear-gradient(0deg, rgba($orange,0.95) 0%, rgba($orange,0.95) 90%, rgba($orange, 0) 100%);
    }

    &.hover-extradark::before {
      background: linear-gradient(0deg, rgba($extradark,0.95) 0%, rgba($extradark,0.95) 90%, rgba($extradark, 0) 100%);
    }

    &.hover-lightblue::before {
      background: linear-gradient(0deg, rgba($lightblue,0.95) 0%, rgba($lightblue,0.95) 90%, rgba($lightblue, 0) 100%);
    }

    &.hover-blue::before {
      background: linear-gradient(0deg, rgba($blue,0.95) 0%, rgba($blue,0.95) 90%, rgba($blue, 0) 100%);
    }

    &.hover-green::before {
      background: linear-gradient(0deg, rgba($green,0.95) 0%, rgba($green,0.95) 90%, rgba($green, 0) 100%);
    }

    &.hover-purple::before {
      background: linear-gradient(0deg, rgba($purple,0.95) 0%, rgba($purple,0.95) 90%, rgba($purple, 0) 100%);
    }
    &.hover-red::before {
      background: linear-gradient(0deg, rgba($red,0.95) 0%, rgba($red,0.95) 90%, rgba($red, 0) 100%);
    }
    &.hover-yellow::before {
      background: linear-gradient(0deg, rgba($yellow,0.95) 0%, rgba($yellow,0.95) 90%, rgba($yellow, 0) 100%);
    }
    p {
      width: 100%;
      max-width: 350px;
      opacity: 0;
      visibility: hidden;
      height: 0;
      -webkit-transition: opacity 0.4s;
      transition: opacity 0.4s;
      position: relative;
      margin-bottom: 0;
    }

    h3 {
      mix-blend-mode: hard-light;
      line-height: 1.2;
      display: inline-block;
      span {
        background: $extradark;
        color: $light;
        position: relative;
        padding: 0 0 4px;
        box-shadow: 8px 0 0 $extradark, -8px 0 0 $extradark;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }


    .text-contain {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .text-move {
      position: absolute;
      // top: 72%;
      bottom: 0;
      left: 1.2rem;
      transform: translateY(0%);
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      mix-blend-mode: hard-light;
      padding-right: 1.2rem;
    }
    .subhead {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: $font-family-base;
      margin: 1rem 0 0;
      span {
        padding-bottom: 0;
      }

      &.subhead-top {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
  }

  &:hover, &:focus-within {
    .hover-extradark, .hover-extradark .text-move h3 span {
      color: $white !important;
    }
    figcaption {
      &::before {
        transform: scaleY(1);
      }
      .text-contain {
        // padding-bottom: 20%;

      }

      .text-move {
        top: 10%;
        transform: translateY(-5%);
        mix-blend-mode: normal;
      }

      h3 span {
        color: $extradark;
        background: transparent;
        box-shadow: none;
      }



      p {
        height: auto;
        opacity: 1;
        visibility: visible;
        color: $extradark;
      }
    }

  }
  &.publication:hover, &.publication:focus {
    figcaption .text-move {
      top: 10%;
    }
  }
  &.col-md-12 {
    max-height: unset;
  }
  &.publication figcaption h3 .subhead:first-child {
    margin-bottom: 1rem;
  }
}

.btn-box {
  margin: 0;
  overflow: hidden;
  background: $extradark;
  max-height: 500px;
  padding: 1.2rem;
  display: flex;

  p {
    width: 100%;
    max-width: 350px;
    height: auto;
    position: relative;
    margin-bottom: 0;
    align-items: center;
  }

  h3 {
    line-height: 1.2;
    display: inline-block;
    align-items: center;
  }
}

.top-left {
  top: 0;
  left: 0;
}

.all-corners {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.filter-hide {
  display: none;
}

@-moz-document url-prefix('') {
    .link-box figcaption h3 {
      line-height: 1.1em;
      span {
        box-shadow: 8px 0 0 $extradark, -8px 0 0 $extradark, 8px -2px 0 $extradark, -8px -2px 0 $extradark;
      }
    }
}

@supports (-ms-ime-align: auto) {
  .link-box figcaption h3 {
    line-height: 1.1em;
    span {
      box-shadow: 8px 0 0 $extradark, -8px 0 0 $extradark, 8px -2px 0 $extradark, -8px -2px 0 $extradark;
    }
  }
}
