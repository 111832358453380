/*.cms-editor-active {
  [data-cms-editor-link] {
    cursor: pointer;
    outline: 0px solid $primary;
    @include transition();
    &:hover {
      outline: 5px solid $primary;
    }
    iframe {
      pointer-events: none;
    }
  }
  .editable {
    outline-color: $primary !important;
  }
}
*/