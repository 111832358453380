.carousel {
  
  overflow: hidden;
  width: 100%;

  &.film {
    height: 41.875vw;
  }

  &.wide {
    height: 56.25vw;
  }

  .carousel-item {
    img, video {
      transform: translate3d(0,0,0);
    }
  }

  .carousel-indicators {
    z-index: 9;

    button {
      width: 100px;
      height: 3px;
      border: 0;
      box-shadow: 0 0 12px rgba(0, 0, 0, 1);
    }
  }

  .carousel-control-prev {
    background: linear-gradient(90deg, rgba($black, 1), rgba($black, .001));

    &:hover {
      opacity: 0.7;

      .carousel-control-prev-icon {
        margin-right: 1.5rem;
      }
    }
  }

  .carousel-control-next {
    background: linear-gradient(270deg, rgba($black, 1), rgba($black, .001));

    &:hover {
      opacity: 0.7;

      .carousel-control-next-icon {
        margin-left: 1.5rem;
      }
    }
  }

  .carousel-item .text-contain {
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 60%;
    line-height: 1em;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.7);
    p {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .carousel-caption {
  z-index: 8;
  &::before {
    content: '';
    background: rgba($black, 0.5);
    width: 300%;
    height: calc(100% + 20px);
    position: absolute;
    left: -50%;
    z-index: -1;
    top: 0;
  }
}

  .plyr--video {
    z-index: auto;
    .plyr__controls {
      z-index: 3;
      padding-bottom: 3rem;
    }
    .plyr__poster {
      background-size: cover;
    }
  }

  // .carousel-inner {
  //   height: 100%;
  //   .carousel-item {
  //     height: 100%;
  //     img {
  //       object-fit: cover;
  //       margin: 0;
  //       height: 100%;
  //     }
  //   }
  // }
}

.landing-layout div[data-lazy-type="yt"] {
  padding-bottom: 58.5%;
}

.home-link {
  position: relative;
  margin: 0;

  figcaption {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    align-items: center;

    &.gradient-right {
      @include linear-gradient(to right, rgba($black, 0) 0%, rgba($black, 1) 100%);
      justify-content: flex-end;
    }

    &.gradient-left {
      @include linear-gradient(to right, rgba($black, 1) 0%, rgba($black, 0) 100%);
    }

    .caption-text {
      max-width: 450px;
    }
  }
}

#home-feature-carousel-1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 1px 6px rgba($black, 0.7);
  .carousel-caption::before {
    //content: none;
    background: linear-gradient(0deg, rgba($black, 0.5), transparent)
  }
}

.bg-beige.captions {
  p {
    margin-bottom: 0;
  }
}