// Color Palette
$white: #ffffff !default;
$off-white: #f0f0ef;
$gray-100: #f8f9fa !default;
$gray-200: #efebe5;
$gray-300: #b3b3b3;
$gray-400: #a7a9ac;
$gray-500: #999999;
$gray-600: #808080;
$gray-700: #707070;
$gray-800: #464443;
$gray-850: #232120;
$gray-900: #081716;
$black: #000000;

$orange: #F3783B;
$beige: #E7E5D8;
$cream: #FFFBEC;
$dark: #5B5552;
$extradark: #272727;


$green: #A5B939;
$purple: lighten(#63418B, 20%);
$blue: #3888BB;
$lightblue: #65B2E8;
$yellow: #F0B93A;
$red: #DD543B;
$teal: #AFDECA;


$primary: $orange;
$secondary: $blue;
$success: $green !default;
$info: $lightblue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #FAF9F5;

// $development: #e5b25d;
// $assets: #86cd82;
// $shots: #59c2df;

// $development: #a0d5db;
// $assets: #65b2e8;
// $shots: #ea6852;



$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'mid': $gray-700,
  'dark': $dark,
  'green': $green,
  'orange': $orange,
  'purple': $purple,
  'blue': $blue,
  'yellow': $yellow,
  'lightblue': $lightblue,
  'black': $black,
  'white': $white,
  'beige': $beige,
  'extradark': $extradark,
  'cream': $cream,
  'teal': $teal,
  'red': $red,
);

$body-bg: $beige;
$body-color: $gray-900;

$font-family-base: 'brandon-grotesque', sans-serif;
$font-family-secondary: 'brandon-grotesque', sans-serif;
$font-hero-new: 'hero-new', sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

:root {
  font-size: 100%;
}

$h1-font-size: 2rem;
$h2-font-size: 1.9rem;
$h3-font-size: 1.7rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.2rem;
$h6-font-size: 0.9rem;

$headings-font-family: 'rockwell', serif;
$headings-font-weight: 400;
$headings-line-height: 1.25;
$headings-color: inherit;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 800px,
  lg: 960px,
  xl: 1140px,
) !default;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$grid-columns: 12;
$grid-gutter-width: 30px;
