// @import 'settings';
@import 'nicole/settings';
@import 'bootstrap';
//
// // Global
// @import 'global/mixins';
@import 'nicole/global/mixins';
@import 'nicole/global/utilities';
// @import 'global/utilities';
// @import 'global/base';
// @import 'global/cognito';
// @import 'global/layout';
@import 'nicole/global/nav';
@import 'nicole/global/color-adjusts';
// @import 'global/typography';
// @import 'global/animations';
// @import 'global/effects';
//
// // Components
// @import 'components/FilmTimeline';
@import 'nicole/components/drawer';
@import 'nicole/components/linkbox';
@import 'nicole/components/carouselpreview';
@import 'nicole/components/footer';
@import 'nicole/components/hero';
@import 'nicole/components/checkbox';
@import 'nicole/components/timeline';
// @import 'components/SplitSection';
// @import 'components/Search';
// @import 'components/Testimonials';
//
// // Pages
// @import 'pages/homepage';
@import 'nicole/pages/homepage';
@import 'nicole/pages/what-we-do';
@import 'nicole/pages/life-at-disney';
@import 'nicole/pages/events';
@import 'nicole/pages/careers';

// @import 'pages/events';
// @import 'pages/homepage';
// @import 'pages/open_positions';
// @import 'pages/process';
// @import 'pages/resources';
//
// // Vendor Overrides
// @import 'vendor/bootstrap';
// @import 'vendor/react-input-range';

@import 'nicole/global/large-screens';
@import 'nicole/global/mobile';
