:root {
  --plyr-color-main: #F3783B;
}


.bg-image,
.bg-video,
.bg-iframe,
.hero,
.responsive-iframe {
  position: relative;
  display: block;
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: $black;
  // background-image: url(https://via.placeholder.com/700x500/f2783c/f2783c?text=placeholder);
  overflow: hidden;
  video {
    height: 105%;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
  &.aspect-1x1 {
    padding-top: 100%;
    video {
      left: -25%;
      height: 100%;
      max-width: none;
      width: auto;
      object-fit: cover;
    }
    video[poster] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  h1 {
    font-size: 80px;
    letter-spacing: 10px;
    font-family: 'brandon-grotesque', sans-serif;
    text-transform: uppercase;
    line-height: 1;
  }

  &.aspect-2x1 {
    padding-top: 50%;
  }
  &.aspect-3x1 {
    padding-top: 33%;
  }
  &.aspect-4x3 {
    padding-top: 75%;
  }
  &.aspect-5x7 {
    padding-top: 120%;
  }
  &.aspect-16x9 {
    padding-top: 56.25%;
  }
  &.aspect-film {
    padding-top: 41.875%;
  }
  &.aspect-portrait {
    padding-top: 125.85%;
  }
  .layer {
    position: absolute !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 110%;
    width: 105%;
    margin-left: -2.5%;
    top: 0;
    left: 0;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .center-text {
    top: 50%;
    // transform: translateY(-50%);
  }
}

.fit-cover {
  object-fit: cover;
  &.position-absolute {
    top: 0;
    left: 0;
  }
}

.fit-contain {
  object-fit: contain;
}

.film-height {
  height: 41.875vw;
}

.aspect-1x1 {
  max-height: 500px;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
}

.btn {
  background: transparent;
  color: $white;
  border: 1px solid $white;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  transition: all 0.3s;
  font-size: 0.8rem;
  padding: 10px 20px;
  &.btn-extradark {
    border-color: $extradark;
    color: $extradark;
  }
  &.btn-yellow {
    border-color: $yellow;
    color: $yellow;
    &:hover {
      color: $extradark;
      background-color: $yellow;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-hover-#{$color}:hover, .btn-hover-#{$color}:focus {
    border-color: $value;
    background-color: $value;
    color: $extradark;
  }

  .btn.btn-collapse.collapse-hover-#{$color}:hover, .btn.btn-collapse.collapse-hover-#{$color}:focus {
    color: $value;
  }

  .btn.btn-collapse.collapse-hover-#{$color}:hover .arrow svg path, .btn.btn-collapse.collapse-hover-#{$color}:focus .arrow svg path {
    stroke: $value;
  }

  .btn.btn-collapse.collapse-hover-#{$color}:focus {
    box-shadow: 0 0 0 0.25rem rgba($value, 0.25);
  }
}

.btn-hover-extradark:hover, .btn-hover-extradark:focus {
  background: $extradark;
  border-color: $extradark;
  color: $white;
}

.sketch-bg-contain.bg-orange .btn {
  border-color: $extradark;
  color: $extradark;
  &:hover {
    color: $white;
    background: $extradark;
  }
}

.sketch-bg-contain.bg-extradark .btn {
  background: transparent;
  &:hover {
    background: white;
  }
}

.drawer-content .btn.btn-orange, .taldev-layout .btn.btn-orange {
  background: $orange;
  border-color: $orange;
  &:hover {
    mix-blend-mode: normal;
    background: darken($orange, 7%);
  }
}

// .btn.btn-purple {
//   color: $purple;
//   border-color: $purple;
//   &:hover {
//     background: darken($purple, 10%);
//     color: $white;
//   }
// }

.btn.btn-dark {
  color: $dark;
  border-color: $dark;
  &:hover {
    background: $dark;
    color: $white;
    mix-blend-mode: multiply;
  }
}

main {
  box-shadow: 0 3px 15px rgba(0,0,0,0.05);
}



.row {
  z-index: 2;
      --bs-gutter-y: 0;
          --bs-gutter-x: 0;
}

#hero-orientation {
  top: 0;
}

.bg-dark, .bg-extradark, .taldev, .teams, .culture {
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

.plyr--video {
  width: 100%;
}


// video {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   max-width: 100%;
//   height: 100%;
//   z-index: 0;
//   object-fit: cover;
// }

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.text-flip {
  min-height: 33vw;
  position: relative;
  overflow-x: hidden;
}


.text-flip:nth-child(even) {
  .col-md-4 {
    order: 2;
  }
  .animate-overlay {
    transform-origin: right;
  }
}

.text-flip.square {
  height: calc(100vw/3);
}

.captions {
  &.bg-overlay {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    p {
      margin: auto;
      color: $white;
      position: relative;
    }
    &::before {
      content: '';
      background:linear-gradient(0deg, rgba($black, 0.75), transparent);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
}

.previously-recorded {
  left: 3rem;
  bottom: 15px;
  text-shadow: 0 0 5px rgba($black, 0.5);
  opacity: 0.7;
}

.carousel .plyr + .previously-recorded {
  z-index: 2;
  bottom: 85px;

}

.plyr + .previously-recorded {
  bottom: 45px;
}

#twitter-feed-container {
  max-height: 500px;
  .timeline-Tweet-text {
    font-size: 16px !important;
    line-height: 1.3;
    margin-bottom: 0;
  }
}

body.drawer-open {
  overflow: hidden;
}

.arrow.stroke-white svg path {
  stroke: white;
}

img[data-lazy-type="image"] {
  transform: scale(1.3);
  filter: blur(2vw);
  &.lazy-loaded {
    filter: blur(0);
    transform: scale(1);
  }
}

#notSupported {
  width: 100%;
  padding: 20% 40px;
  text-align: center;
  display: none;
  color: #fff;
  background: $extradark;
}

.drawer.open {
  .row {
    justify-content: center;
    &.sketch-bg-contain {
      justify-content: flex-start;
    }
    &.timeline-contain {
      justify-content: center;
    }
  }
}


a.ot-img {
  position: relative;
  &::before {
    content: '\25BA';
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    border-radius: 100%;
    transform: translate(-50%);
    background: rgba($orange, 0.9);
    color: $white;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 0 0 3px;
    box-sizing: border-box;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk.otPcCenter #ot-pc-title {
  font-size: 26px !important;
}

#onetrust-pc-sdk.otPcCenter .ot-cat-item p {
  font-size: 16px !important;
}