.films {
  .copy {
    padding: 12rem 0;
  }
}

.py-10 {
  padding: 10rem 0;
}

#films {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  .film {
    height: auto !important;
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 147% !important;
    }
    figcaption {
      top: 0;
      left: 0;
      background: transparent;
      .text-move {
        // width: 85%;
        // height: 70%;
        left: 0.5rem;
        padding-right: 0.5rem;
      }
      p {
        width: 100%;
        position: relative;
        margin-top: auto;
        color: $white;
      }
      h4 {
        opacity: 0;
        transition: all 0.4s;
      }
    }
    &:hover, &:focus-within {
      h4 {
        top: 1.5em;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.link-box.shortcircuit {
  &.link-box.col-md-4::before {
    padding-top: 56.25% !important;
  }
}

.sketch-bg-contain {
  position: relative;
  // margin: 0;
  .sketch-bg {
    overflow: hidden;

  }
}

.sketch-bg-contain.pinned {
  z-index: unset;
}

#filter {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  z-index: 12;
  width: auto;
  max-width: none;
  .filter-type {
    margin-left: 10px;
    &:focus + .filter-checkbox span:first-child {
      border-color: $white;
    }
  }
  label {
    margin-bottom: 0;
  }
  &.pinned {
    position: fixed !important;
    top: 0;
  }
  .dropdown-menu-right {
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: 0 4px 10px rgba($extradark, 0.5);
    min-width: 100%;
    right: 0;
  }
  &.publication-filter {
    .dropdown-contain {
      justify-content: space-between;
    }
    .dropdown-menu-right {
      width: calc(100vw/3 * 2);
      height: 540px;
      .dropdown-wrapper {
        height: 90%;
        padding: 10px;
      }
      .publication-categories {
        height: 100%;
        padding: 0;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 50px;
  }
}

#filter-trigger {
  opacity: 0;
  visibility: hidden;
  height: 1px;
  background: transparent;
  width: 1px;
  bottom: 50px;
}

@media screen and (max-width: $screen-md-min) {
  #films {
    grid-template-columns: 1fr 1fr 1fr;
    .film {
      figcaption {
        .text-move {
          height: 100%;
        }
        .text-contain {
          p {
            visibility: hidden;
            opacity: 0;
            height: 0;
          }
        }
      }
      &:hover {
        .text-contain {
          height: 80%;
          p {
            visibility: visible;
            opacity: 1;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $screen-xl-min) {
  #films  {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.dropdown {
  .btn {
    border: none;
    padding: 10px 50px;
    .arrow {
      width: 6px;
      padding-bottom: 2px;
      svg {
        transform: rotate(90deg);
        transition: all 0.3s;
        width: 100%;
        height: 100%;
      }
    }
    &::after {
      content: none;
    }
  }
  &.show {
    .btn .arrow svg {
      transform: rotate(-90deg);
    }
  }
}

a.publication  {
  text-decoration: none;
  color: $orange;
  transition: all 0.2s;
  .icon {
    width: 30px;
    height: 30px;
    svg {
      width: 100%;
      height: auto;
    }
  }

  &:hover {
    color: darken($orange, 5%);
    .icon {
      .fill-orange {
        fill: darken($orange, 5%);
      }
    }
  }

}

.fill-white {
  fill: $white;
  transition: all 0.3s;
}

.fill-orange {
  fill: $orange;
  transition: all 0.3s;
}

.fill-black {
    fill: $black;
}

.asset-description {
  margin-left: calc(30px + 1rem);
}

.contains-filter {
  z-index: 4;
}

#selected-categories {
  white-space: nowrap;
  text-transform: capitalize;
}

#CurrentProjects-carousel-1 {
  height: 56.25vw;
}
