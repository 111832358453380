.person {
  // height: 31.4vw;
  overflow: hidden;
  margin-bottom: 0;
  div.portrait-contain {
    padding-top: 100%;
  }
  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    @include linear-gradient(to bottom, rgba($extradark, 0) 0%, rgba($extradark, 1) 100%);
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.4s;

  }
  .person-name {
    text-align: center;
    margin: 0;
  }
  .quote {
    opacity: 0;
    transform: translateY(30px);
    visibility: hidden;
    height: 0;
  }
  &:hover figcaption {
    height: 120%;
    @include linear-gradient(to bottom, rgba($extradark, 0) 0%, rgba($extradark, 0.9) 8%, rgba($extradark, .9) 100%);
    justify-content: center;
    p {
      transition: all 0.4s 0.1s;
    }
    .quote {
      height: auto;
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.culture {
  figure {
    figcaption {
      padding: 5%;
      right: 4.25%;
    }
    &:nth-child(odd) {
      img {
        margin-left: auto;
      }
      figcaption {
        left: 4.25%;
        right: auto;
      }
    }
  }
  figcaption {
    padding: 5%;
    right: 4.25%;
    &:nth-child(odd) {
      left: 4.25%;
      right: auto;
    }
  }
}

.btn.people-btn {
  &:hover {
    background: $white;
  }
}

.animate-overlay {
  background: inherit;
  transform-origin: left;
}

.row.text-flip .col-lg-8 {
  background: inherit;
}


@media screen and (max-width: $screen-lg-min) {
  .culture figcaption {
    position: static !important;
    padding: 10% 5%;
  }

}
