.sketch-bg {
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.15;
  pointer-events: none;
}
@media screen and (max-width: $screen-md-min) {
  .hero.aspect-film {
    // padding-top:56.25%;
    h1 {
      font-size: 2.8em;
      // padding-left: 2rem !important;
    }
  }
  .i8t4 {
    div.col-md-8 {
      order: 1;
    }
    div.col-md-4 {
      padding: 10% 0;
      order: 2;
    }
  }
}

#hero {
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 41.875%;
  img {
    height: 115% !important;
    will-change: opacity, transform;
  }
  .previously-recorded {
    left: calc(50px + 1rem);
  }
  #hero-text {
    padding-left: calc(50px + 1rem);
    transform: translateY(-50%);
    max-width: 80%;
    h1 {
      // word-spacing: 10000px;
      text-shadow: 0 0 15px rgba(0,0,0,0.3);
    }
    p {
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 0 0 15px rgba(0,0,0,0.3);
    }
  }
}

.hero-contain .scrollmagic-pin-spacer {
  height: auto !important;
  #hero {
      // height: auto;
      video {
        // position: static;
      }

    }
  }

  #process-stack {
    .final-frame {
      transform: perspective(10px);
    }
    .layer {
      opacity: 0;
      background: $white;
      width: 100%;
      height: 402px;
      box-shadow: 1px 1px 30px rgba(0,0,0,0.5);
      transition: all 0.7s;
      top: 0;
      &:nth-child(odd) {
        background: $green;
      }
      &.layer-transform {
        transform: rotateX(68deg) skewX(-32deg) scale(0.5);
      }
      &.layer-final {
        opacity: 1;
      }
    }
  }

  $elements: 15;
  @for $i from 0 to $elements {
    #process-stack .layer.stack.layer-transform:nth-child(#{$i}) {
      top: #{$i * -30px};
      // transform: rotateX(68deg) skewX(-32deg) scale(0.5) translateY(#{$i * -30px});
      opacity: 1;
    }
  }

.subtitle {
    text-transform: uppercase;
    letter-spacing: 2px;
}
