@media screen and (max-width: 1600px) {
  .drawer.open .drawer-content {
    .link-box {
      flex: 0 0 50%;
      max-width: 50%;

      &:last-child:nth-child(odd) {
        max-width: 100%;
        flex: 0 0 100%;
        height: 400px;
        width: 100%;

        .text-move {
          max-width: 45%;

          p {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .drawer-content {
    width: 75%;
  }
}

@media screen and (max-width: $screen-xl-min) {
  .drawer-content {
    width: 90%;
    .row .link-box:last-child:nth-child(odd) {
      max-width: 100%;
      flex: 0 0 100%;
      height: 400px;
      width: 100%;
      .text-move {
        max-width: 45%;
        p {
          width: 100%;
        }
      }
  }
}
}

@media screen and (max-width: 1200px) {
  .link-box figcaption .subhead {
    margin: 0.8rem 0 0;
    &.subhead-top {
      margin-bottom: 0.8rem;
      margin-top: 0;
    }
  }
  .link-box.publication figcaption h3 .subhead:first-child {
    margin: 0 0 0.7rem;
  }
}





@media screen and (max-width: 1100px) {
  .link-box.publication {
    figcaption h3 {
      font-size: 1.6rem;
    }
    &:hover {
      figcaption .text-move {
        height: 90%;
        overflow: hidden;
        top: 5%;
      }

    }

  }
  header nav #primarynav {
    width: calc(100%* 2/3);
  }

  #films .film h4 {
    font-size: 1.6rem;
  }

  .drawer-content {
    .upcoming-film {
      // align-items: center !important;
      justify-content: center !important;
      .col-10.col-sm-6.col-md-3.px-md-3.px-5.px-sm-0.mb-5.mb-md-0.mx-auto.mx-md-0, .col-md-7.col-lg-5.col-10.offset-1.editable.text-white.py-md-5 {
        flex: 0 0 75%;
        max-width: 75%;
        margin: 0 !important
      }
      .col-md-7.col-lg-5.col-10.offset-1.editable.text-white.py-md-5 {
        max-width: 560px;
        padding-top: 1.5rem;
      }
    }
  }

}

@media screen and (max-width: $screen-lg-min) {
  .row .link-box:last-child:nth-child(odd) {
    max-width: 100%;
    flex: 0 0 100%;
    height: 400px;
    width: 100%;
    .text-move {
      max-width: 45%;
      p {
        width: 100%;
      }
    }
    // border: 1px solid red;
  }

  .btn-box:last-child:nth-child(odd) {
    max-width: 100%;
    flex: 0 0 100%;
    height: 400px;
    width: 100%;
  }

  #home-feature-carousel-1 {
    height: 48vw;
  }
  .drawer-content {
    width: 100%;

    .col-md-6.p-5.ms-5 {
      max-width: 58.3333333333%;
      flex: 0 0 58.3333333333%;
    }
    .questions-contain {
      margin-left: 0 !important;
    }
  }
  .perks-contain .row {
    justify-content: center;
  }

  .link-box figcaption .subhead {
    margin: 1rem 0 0;
    &.subhead-top {
      margin-bottom: 1rem;
      margin-top: 0;
    }
  }
}



@media screen and (max-width: 1050px) {
  #filter.publication-filter .dropdown-menu-right {
    width: 100vw;
  }
  #hero #hero-text h1 {
    font-size: 60px !important;
  }
}

@media screen and (max-width: 824px) {
  #filter {
    width: 100vw !important;
    left: 0 !important;
    max-width: 100vw !important;
    box-shadow: 0 5px 5px rgba($black, 0.1);
    .selected-teams {
      width: 100%;
      justify-content: flex-end;
    }
    .dropdown-menu.show {
      width: 100vw;
    }
    &.publication-filter {
      .type-contain {
        flex-grow: 2;
        justify-content: space-around;
      }
      .dropdown-menu.show {
        .dropdown-wrapper {
          height: 100%;
          overflow-y: scroll;
          padding: 10px;
          margin-bottom: 10px;
          .publication-categories {
            height: 220%;

          }
        }
      }
    }
  }
  .pinned #filter {
    top: 50px !important;
  }
  .drawer-content {
    .col-md-4.ps-5.py-5.m-0.d-flex.flex-column {
      padding-right: 3rem !important;
      max-width: 100%;
      flex: 0 0 100%;
      padding-bottom: 1rem !important;
    }
    .col-md-6.p-5.ms-5 {
      max-width: 100%;
      flex: 0 0 100%;
      margin-left: 0 !important;
      padding-top: 0 !important;
    }
    p {
      max-width: 600px;
    }
  }
  .carousel {
    height: 56vw;
  }
}


@media screen and (max-width: $screen-md-min) {
  #home-feature-carousel-1 {
    height: 80vw;
  }
  .carousel {
    .carousel-item {
      .text-contain {
        width: 100%;
        bottom: 35px;
        p {
          font-size: 80% !important;
          margin-bottom: -5px;
        }
        small {
          font-size: 60% !important;
        }
      }
      .plyr--video .plyr__controls {
        padding-bottom: 1.5rem;
      }
      .plyr+.previously-recorded {
        bottom: 50px;
      }
    }
    .carousel-indicators li {
      width: 50px;
      height: 2px;
    }

  }
  
  
  .text-flip .col-md-4 {
    order: 2;
  }

  .link-box {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    min-height: 350px;
    &.filter-hide {
      display: none;
    }
    &::before {
      padding-top: 0 !important;
      display: block;
      content: "";
      width: 100%;
    }
    &.people .header-line.bg-extradark {
      background: $white !important;
    }
    figcaption {
      @include linear-gradient(55deg, rgba($extradark, 1) 0%, rgba($extradark, 0.55) 50%, rgba($extradark, 0.22) 68%, rgba($extradark, 0) 100%);
      justify-content: center;
      position: relative !important;
      padding: 3rem 2.2rem !important;
      align-items: center !important;
      .text-contain {
        height: auto;
        .text-move {
          position: relative;
          top: auto;
          transform: none;
          mix-blend-mode: normal;
          text-shadow: 1px 1px 5px rgba(0,0,0,0.4);
          padding-right: 0;
          left: 0;
        }
        p {
          visibility: visible;
          opacity: 1;
          height: auto;
          width: 100%;
          max-width: 350px;
        }
        h3 {
          mix-blend-mode: normal;
          span {
            background: transparent;
            box-shadow: none;
          }
          .subhead {
            margin-top: 7px;
            &.subhead-top {
              margin-top: 0;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
    .header-line {
      display: block;
    }
    &:hover {
      figcaption {
        .text-contain {
          padding-bottom: 0;
          .text-move {
            text-shadow: 1px 1px 5px rgba(0,0,0,0);
          }
        }
        .header-line {
          background: rgba($light, 0.3) !important;
        }

      }
    }
    &.publication {
      .text-move p {
        max-width: 90%
      }
    }
  }
  .previously-recorded {
    font-size: 60%;
    left: 1.5rem;
  }
  .plyr + .previously-recorded {
    bottom: 35px;
    left: 2.7rem;
  }
  .hero.aspect-film {
    padding-top: 80%;
  }
  #hero {
    padding-top: 80%;
    #hero-text {
      max-width: 100%;
      padding: 0 25px;
      p {
        margin-bottom: 0.1rem;
        font-size: 80%;
      }
      h1 {
        letter-spacing: 1.5px;
        font-size: 4rem !important;
      }
    }
  }
  .upcoming-film {
    // align-items: flex-start !important;
    justify-content: flex-start !important;
  }
  .sketch-bg-contain {
    .sketch-bg img {
      object-position: 80% center;
    }
  }
  header nav #primarynav {
    width: 100%;
  }
  
  .header-line {
    margin-bottom: 10px;
  }

  .row.text-flip .col-lg-4 {
    order: 2;
    padding: 3rem !important;
    p {
      max-width: 400px;
    }
  }

  #faq .questions {
    padding-top: 0 !important;
  }
  .text-flip.square {
    height: auto;
  }
  .row .link-box:last-child:nth-child(odd) {
    height: auto !important;
    .text-move {
      max-width: 45%;
      p {
        width: 100%;
      }
    }
  }

  .py-10 {
    padding: 3rem 15px;
  }
  #films .film {
    min-height: unset;
    figcaption {
      position: absolute !important;
      padding: 0.75rem !important;
      .text-move {
        left: 1rem;
        padding-right: 1.5rem;
        h4 {
          font-size: 1.3rem;
        }
        small {
          font-size: 80%;
        }
      }
    }
  }
  .culture {
    .culture-image img {
      position: relative !important;
    }
  }
  figure.people {
    min-height: 350px;
    .text-move {
      max-width: none !important;
    }
  }
  .row .link-box:last-child:nth-child(odd) {
    .text-move {
      max-width: unset;
    }
  }
  .drawer.open .drawer-content .link-box {
    max-width: 100%;
    flex: 0 0 100%;
  }
  
  .drawer .btn {
    margin: 1.5rem  0;
  }
  
}

@media screen and (max-width: $screen-sm-min) {
  #hero #hero-text h1 {
    font-size: 2.5rem !important;
  }




}

figcaption small p {
  margin-bottom: 0;
}
