

$elements: 10;
@for $i from 1 to $elements {
  /*
    Darken Colors
  */
  .bg-blue[data-darken-bg="#{$i}"], .bg-blue[data-darken-level="#{$i}"] {
    background: darken($blue, percentage($i/15)) !important;
  }
  .bg-lightblue[data-darken-bg="#{$i}"], .bg-lightblue[data-darken-level="#{$i}"] {
    background: darken($lightblue, percentage($i/15)) !important;
  }
  .bg-orange[data-darken-bg="#{$i}"], .bg-orange[data-darken-level="#{$i}"] {
    background: darken($orange, percentage($i/15)) !important;
  }
  .bg-green[data-darken-bg="#{$i}"], .bg-green[data-darken-level="#{$i}"] {
    background: darken($green, percentage($i/15)) !important;
  }
  .bg-purple[data-darken-bg="#{$i}"], .bg-purple[data-darken-level="#{$i}"] {
    background: darken($purple, percentage($i/15)) !important;
  }
  .bg-red[data-darken-bg="#{$i}"], .bg-red[data-darken-level="#{$i}"] {
    background: darken($red, percentage($i/15)) !important;
  }
  /*
    Lighten Colors
  */
  .bg-blue[data-lighten-bg="#{$i}"], .bg-blue[data-lighten-level="#{$i}"] {
    background: lighten($blue, percentage($i/15)) !important;
  }
  .bg-lightblue[data-lighten-bg="#{$i}"], .bg-lightblue[data-lighten-level="#{$i}"] {
    background: lighten($lightblue, percentage($i/15)) !important;
  }
  .bg-orange[data-lighten-bg="#{$i}"], .bg-orange[data-lighten-level="#{$i}"] {
    background: lighten($orange, percentage($i/15)) !important;
  }
  .bg-green[data-lighten-bg="#{$i}"], .bg-green[data-lighten-level="#{$i}"] {
    background: lighten($green, percentage($i/15)) !important;
  }
  .bg-purple[data-lighten-bg="#{$i}"], .bg-purple[data-lighten-level="#{$i}"] {
    background: lighten($purple, percentage($i/15)) !important;
  }
  .bg-red[data-lighten-bg="#{$i}"], .bg-red[data-lighten-level="#{$i}"] {
    background: lighten($red, percentage($i/15)) !important;
  }
  /*
    Figure Hover Colors
  */
  figure[data-lighten-bg="#{$i}"] div.hover-blue::before {
    background: linear-gradient(0deg, rgba(lighten($blue, percentage($i/15)),0.95) 0%, rgba(lighten($blue, percentage($i/15)),0.95) 90%, rgba(lighten($blue, percentage($i/15)), 0) 100%) !important;
  }
  figure[data-lighten-bg="#{$i}"] div.hover-lightblue::before {
    background: linear-gradient(0deg, rgba(lighten($lightblue, percentage($i/15)),0.95) 0%, rgba(lighten($lightblue, percentage($i/15)),0.95) 90%, rgba(lighten($lightblue, percentage($i/15)), 0) 100%) !important;
  }
  figure[data-lighten-bg="#{$i}"] div.hover-orange::before {
    background: linear-gradient(0deg, rgba(lighten($orange, percentage($i/15)),0.95) 0%, rgba(lighten($orange, percentage($i/15)),0.95) 90%, rgba(lighten($orange, percentage($i/15)), 0) 100%) !important;
  }
  figure[data-lighten-bg="#{$i}"] div.hover-green::before {
    background: linear-gradient(0deg, rgba(lighten($green, percentage($i/15)),0.95) 0%, rgba(lighten($green, percentage($i/15)),0.95) 90%, rgba(lighten($green, percentage($i/15)), 0) 100%) !important;
  }
  figure[data-lighten-bg="#{$i}"] div.hover-purple::before {
    background: linear-gradient(0deg, rgba(lighten($purple, percentage($i/15)),0.95) 0%, rgba(lighten($purple, percentage($i/15)),0.95) 90%, rgba(lighten($purple, percentage($i/15)), 0) 100%) !important;
  }
  figure[data-lighten-bg="#{$i}"] div.hover-red::before {
    background: linear-gradient(0deg, rgba(lighten($red, percentage($i/15)),0.95) 0%, rgba(lighten($red, percentage($i/15)),0.95) 90%, rgba(lighten($red, percentage($i/15)), 0) 100%) !important;
  }
}
