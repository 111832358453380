// .carousel-preview {
//   .carousel-inner {
//     .active.carousel-item {
//       transform: translate3d(-75%,0,0);
//     }
//   }
// }


.carousel-preview  {
  height: 27.91vw !important;
  .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          // right: -33.3333%;  /*change this with javascript in the future*/
          // transform: translate3d(150%,0,0);
          z-index: -1;
          display: block;
          visibility: hidden;
          transform: translate3d(175%,0,0);
      }
      .carousel-item {
        margin-right: 0;
        display: block !important;
        position: absolute;
        visibility: hidden;
        transform: translate3d(-125%,0,0);
        transition-timing-function: linear;
        // transition: transform 1s ease-out !important;
    }
    /*
    this is the positioning for the image to the left (happens to be "active")
    */
    .carousel-inner .active {
      transform: translate3d(-75%,0,0);
      visibility: visible;
    }
    .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item  {
      transform: translate3d(175%,0,0);
      visibility: visible;
    }
    /*
    this is the positioning to center the active image
    */
    .carousel-inner .active + .carousel-item {
        display: block;
        position: absolute;
        transform: translate3d(25%,0,0);
        z-index: 1;
        visibility: visible;
    }
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
    .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
        // transition: none;
    }
    /* left or forward direction */
    // .active.carousel-item-left + .carousel-item-next.carousel-item-left,
    // .carousel-item-next.carousel-item-left + .carousel-item,
    // .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    //     position: absolute;
    //     // transform: translate3d(25%, 0, 0);
    //     visibility: visible;
    //
    // }
    /* farthest right hidden item must be also positioned for animations */
    .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        visibility: visible;
    }
    /* right or prev direction */
    // .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
    // .carousel-item-prev.carousel-item-right + .carousel-item,
    // .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    //     position: absolute;
    //     // transform: translate3d(-75%, 0, 0);
    //     visibility: visible;
    //     display: block;
    //     visibility: visible;
    //
    // }
    /*
    this is the positioning for the image to the right
    */
    .carousel-inner .active + .carousel-item + .carousel-item {
        display: block;
        position: absolute;
        transform: translate3d(125%,0,0);
        visibility: visible;
    }

    // move next item to final spot
    .carousel-inner .carousel-item-next.carousel-item-left {
        position: absolute;
        transform: translate3d(-75%, 0, 0);
        visibility: visible;
    }
    .carousel-inner .carousel-item-next.carousel-item-left + .carousel-item {
      transform: translate3d(25%,0,0);
      visibility: visible;
    }
    .carousel-inner .carousel-item.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
      visibility: visible;
      transform: translate3d(125%,0,0);
      position: absolute;
    }
    .carousel-inner .active.carousel-item-left {
      transform: translate3d(-125%, 0, 0);
      visibility: visible;
    }

    // move next item to final spot
    .carousel-inner .carousel-item-prev.carousel-item-right {
        position: absolute;
        transform: translate3d(-75%, 0, 0);
        visibility: visible;
    }
    .carousel-inner .carousel-item-prev.carousel-item-right + .carousel-item {
      transform: translate3d(25%,0,0);
      visibility: visible;
    }
    .carousel-inner .carousel-item.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
      visibility: visible;
      transform: translate3d(125%,0,0);
      position: absolute;
    }
    .carousel-inner .active.carousel-item-right {
      transform: translate3d(-125%, 0, 0);
      visibility: visible;
    }


}
