
.team {
  figcaption {
    top: 0;
    left: 0;
    background: #24242482;
    justify-content: center;
    color: #fff;
    letter-spacing: 3.3px;
    align-items: center;
  }
  img {
    transition: all 0.3s ease;
  }
  &:hover img {
    transform: scale(1.1);
  }
}

.btn.btn-collapse {
  color: $dark;
  border: none;
  text-transform: none;
  letter-spacing: 0;
  transition: all 0.3s;
  &.collapsed .arrow svg {
    transform: rotate(90deg);
  }
  .arrow  {
    width: 20px;
    svg {
      transform: rotate(-90deg);
      transition: transform 0.3s;
      width: 100%;
    }
  }

  &:hover {
    text-decoration: none;
    color: $orange;
    mix-blend-mode: normal;
    .arrow svg path {
      stroke: $orange;
    }
  }
  &:focus {
    text-decoration: none;
  }
}

.perk img {
  mix-blend-mode: luminosity;
}

$elements: 10;
@for $i from 1 to $elements {
  div.taldev[data-taldev-index="#{$i}"] {
    background: darken($green, percentage($i/15));
  }
}

.hover-green:hover {
    background: $green;
}

#social-videos {
  margin-bottom: -1px;
  div.position-relative {
    height: 100%;
    video {
      height: 100%;
      object-fit: cover;
    }
  }
}

.perks-contain .row {
  justify-content: space-around;
}

.row.text-flip.bg-dark {
  p {
    max-width: 400px;
  }
}

.icon-grid-container {
  img {
    height: auto !important;
  }
}
