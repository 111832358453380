header {
  nav {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    img {
      height: auto;
    }

    &::before {
      content: '';
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      z-index: -1;
      height: 100px;
      background: rgba($extradark, .75);
      height: 50px;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }

    &.filter-pinned::before {
      background: $extradark;
    }


    &.hero-nav {
      &::before {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        z-index: -1;
        height: 100px;
        background: linear-gradient(to bottom, rgba($black, 0.55) 0%, rgba($black, 0.18) 70%, rgba($black, 0) 100%);
        -webkit-backdrop-filter: blur(0px);
        backdrop-filter: blur(0px);
      }
    }

    .overlay {
      display: none;
    }

    .main-contain {
      align-items: center;
      z-index: 2;
      a.ms-3 {
        padding: 10px 0;
        height: 50px;
      }
    }

    .navbar-toggler {
      width: 50px;
      height: 50px;
      background: $orange;
      justify-content: center;
      align-items: center;
      border: none;

      &:focus {
        outline: 5px auto $orange;
        color: transparent !important;
        background: $orange;
      }

      &:hover {
        background: $orange;
        .nav-icon-contain {
          transform: scale(1.2);
          transition: all 0.1s 0.2s cubic-bezier(0,.67,.43,1.63);

          &.close {
            transform: none;
            opacity: 1;

            .icon-dot {
              left: -12px !important;
              top: 12px !important;
            }
          }
        }

        .icon-dot {
          top: 6px;
          left: -5px;

          &:nth-child(2) {
            left: 5px;
          }

          &:nth-child(3) {
            top: 12px;
            left: 0;
            transform: scale(1.5);
          }
        }
      }

      .nav-icon-contain {
        width: 7px;
        height: 28px;
        transition: all 0.1s cubic-bezier(0,.67,.43,1.63);

        &.close {
          transform: scale(1);
          transition: none;
          opacity: 1;

          .icon-dot {
            width: 30px;
            height: 3px;
            border-radius: 10px;
            transform: rotate(45deg);
            left: -12px;
            top: 12px;

            &:nth-child(2) {
              transform: rotate(-45deg);
            }

            &:nth-child(3) {
              transform: scale(0);
            }
          }
        }
      }
    }

    .icon-dot {
      width: 7px;
      height: 7px;
      position: absolute;
      left: 0;
      border-radius: 50%;
      background: $extradark;
      transition: all 0.2s ease-in-out;
      z-index: 1;
      &:nth-child(2) {
        top: 10px;
      }

      &:nth-child(3) {
        top: 20px;
      }
    }

    // img {
    //   height: 30px;
    // }

    #primarynav {
      // width: 0;
      // min-width: 0;
      height: 100vh;
      background: #272727;
      // position: fixed;
      // top: 0;
      // left: 0;
      z-index: -1;
      overflow-x: hidden;
      // opacity: 0;
      width: calc(100%/3);
      min-width: 350px;
      // transform: translateX(-100%);
      // transform-origin: left;
      // transition: transform 0.3s;
      // visibility: hidden;

      .nav-link-contain {
        margin-top: 75px;
        padding: 0;
      }

      li {
        list-style: none;

        a {
          color: #fff;
          text-decoration: none;
        }

        a:hover {
          text-decoration: none;
        }
      }

      .nav-item-parent {
        border-bottom: 1px solid lighten($extradark, 5%);
        // padding-bottom: 10px;
        a {
          padding: 20px 0 20px 25px;
          transition: none;
        }

        h4 {
          margin: 0;
        }

        .btn {
          border: none;
          order: 2;
          transition: none;
        }
      }

      .nav-item-child {
        a {
          padding-left: calc(25px + 1rem);
        }

        ul li a {
          padding-left: calc(25px + 2rem);
        }
      }

      .nav-item {
        transition: all 0.3s;
        svg path {
          stroke: #696868;
        }
        &.nav-item-child {
          padding: 5px 0;
        }
        ul {
          padding: 10px 0;
          background: darken($extradark, 8%);
          &#wwd-subnav {
            padding-bottom: 5px;
          }
        }
        &:hover {
          svg path {
            stroke: $white;
          }
        }
        &.hover-red {
          &:hover {
            .nav-item-parent {
              a {
                background: $red;
                color: $extradark;

              }
              .btn {
                background: darken($red, 5%);
                &:hover, &:active {
                  background: darken($red, 10%);
                }
              }
            }
          }
          a:hover {
            color: $red;
          }
          .btn:not(.collapsed) {
            background: darken($red, 5%);
            svg path {
              stroke: $white;
            }
            + a {
              background: $red;
              color: $extradark;
            }
          }
        }
        &.hover-lightblue {
          &:hover {
            .nav-item-parent {
              background: $lightblue;
              a {
                color: $extradark;
              }
              .btn {
                background: darken($lightblue, 5%);
                &:hover, &:active {
                  background: darken($lightblue, 10%);
                }
              }
            }
          }
          a:hover {
            color: $lightblue;
          }
          .btn:not(.collapsed) {
            background: darken($lightblue, 5%);
            svg path {
              stroke: $white;
            }
            + a {
              background: $lightblue;
              color: $extradark;
            }
          }
        }
        &.hover-orange {
          &:hover {
            .nav-item-parent {
              background: $orange;
              a {
                color: $extradark;
              }
              .btn {
                background: darken($orange, 5%);
                &:hover, &:active {
                  background: darken($orange, 10%);
                }
              }
            }
          }
          a:hover {
            color: $orange;
          }
          .btn:not(.collapsed) {
            background: darken($orange, 5%);
            svg path {
              stroke: $white;
            }
            + a {
              background: $orange;
              color: $extradark;
            }
          }
        }
        &.hover-yellow {
          &:hover {
            .nav-item-parent {
              background: $yellow;
              a {
                color: $extradark;
              }
              .btn {
                background: darken($yellow, 5%);
              }
            }
          }
          a:hover {
            color: $yellow;
          }
        }
        &.hover-teal {
          &:hover {
            .nav-item-parent {
              background: $teal;
              a {
                color: $extradark;
              }
              .btn {
                background: darken($teal, 5%);
              }
            }
          }
          a:hover {
            color: $teal;
          }
        }
        &.hover-beige {
          &:hover {
            .nav-item-parent {
              background: $beige;
              a {
                color: $extradark;
              }
              .btn {
                background: darken($beige, 5%);
              }
            }
          }
          a:hover {
            color: $beige;
          }
        }
        a.hover-purple:hover {
          color: $purple;
        }
        a.hover-green:hover {
          color: $green;
          background: transparent;
        }
      }


    }
    .offcanvas-backdrop.show {
      opacity: 0.7;
      z-index: -100;
      cursor: pointer;
      width: 100vw;
      height: 100vh;
      position: absolute;
      background: #000000;
      top: 0;
      left: 0;
    }
  }

  // .offcanvas-start {
  //   opacity: 0;
  //   transition: all 0.3s ease-in-out;
  //   &.show {
  //     opacity: 1;
  //   }
  // }
}

#trigger-nav {
  height: 50px;
  bottom: 0;
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
}

.ps-nav-space {
  padding-left: calc(50px + 1rem);
}

.social-icons-contain {
  .social-icon {
    width: 50px;
    height: 50px;
    background: $extradark;
    transition: all 0.3s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      transition: all 0.3s;
      height: auto;
    }
    &:hover {
      background: $orange;
      img {
        transform: scale(0.9);
      }
    }
  }
  &.careers-icons {
    .social-icon {
      //background: rgba($light, 0.1);
      &:hover {
        background: darken($orange, 5%)
      }
    }
  }
}

#mainnav.active {
  #primarynav {
    visibility: visible;
  }
}

.header-social-icons {
  padding: 25px;
  color: $light;
  .social-icons-contain .social-icon {
    background: lighten($extradark, 5%);
    &:hover {
      background: $orange;
    }
  }
}
