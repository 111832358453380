.drawer-content {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% * 2 / 3);
  max-width: 1420px;
  height: 100%;
  z-index: 1000;
  background-color: $light;
  overflow-y: auto;
  transform: translateX(100%);
  color: $dark;
  @include transition(transform, 0.5s, ease-out);

  h1 {
    font-size: 2.4rem !important;
  }

  .hero h1 {
    font-size: 80px !important;
  }

  h2 {
    font-size: 1.9rem !important;
  }

  h4 {
    font-size: 1.3rem !important;

    strong {
      letter-spacing: 0.8px;
    }
  }

  p {
    font-size: 1rem !important;
  }

  h5 {
    font-size: 1.2rem !important;
  }

  .social-line {
    margin-top: 1rem !important;

    p {
      font-size: 80% !important;
    }
  }

  .social-icons-contain .social-icon {
    width: 35px;
    height: 35px;
  }

  .link-box figcaption .text-move {
    left: 2.3rem;
  }
}

.drawer {
  &.open {
    .drawer-content {
    
      transform: translateX(0);

      .close-drawer {
        position: -webkit-sticky !important;
        position: sticky !important;
        z-index: 1000;
        * {
          pointer-events: none;
        }
      }

      img {
        background-color: transparent;
      }

      .hero {
        margin-top: -50px;
        #hero img {
          height: 100% !important;
        }
      }

      .not-found {
        height: 100%;
        padding: 0;

        #hero {
          padding: 0;
          height: 100%;
        }

        img {
          opacity: 0.5;
        }
      }

      .link-box {
        min-width: 50%;
      }

      .link-box .text-move p {
        width: 100%;
        max-width: 450px;
      }
    }

      .bg-drawer {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }

      .question:last-child {
        border-bottom: 0 !important;
      }

      .carousel {
        height: 0;
        padding-bottom: 41.875%;

        .carousel-inner {
          position: absolute;
        }
      }
    }
  }

.bg-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.7);
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  pointer-events: none;
  @include transition(opacity, 0.5s, ease-out);
}

.close-drawer {
  color: $white;
  background-color: $orange;
  border: 0;
  width: 50px;
  height: 50px;
  z-index: 1;
  font-size: 25px;
  margin: 0;
  transition: all 0.3s;

  &:hover {
    background-color: darken($orange, 5%);
  }

  &:focus {
    outline: none;
  }

  svg {
    transform: rotate(-180deg);
  }
}

.mt-50 {
  margin-top: -50px;
}

.publication-author {
  line-height: 1.7;
}

body.drawer-layout {
  h1 {
    font-size: 2.7rem !important;
  }

  #hero h1 {
    font-size: 80px !important;
  }

  .row {
    justify-content: center;
    &.sketch-bg-contain {
      justify-content: flex-start;
      &.timeline-contain {
        justify-content: center;
      }
    }
  }


  h2 {
    font-size: 2.2rem !important;
  }
}

.question-body p {
  margin-bottom: 0;
}
