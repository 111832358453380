.timeline {

    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    p {
        margin-top: 0;
    }

    .timeline-item {
        /*TEXT*/
        position: relative;
        padding: 0px 0px 20px 50px;
        margin-left: 40px;

        /*CIRCLE*/
        &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            top: 10px;
            left: 0;
        }

        /*LINE*/
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 100%;
            top: 10px;
            left: 4px;
        }

        &:last-child::after {
            content: none;
        }
    }




}

@each $color,
$value in $theme-colors {

    @for $i from 0 to 10 {

        .timeline .timeline-item-#{$color}[data-darken-level="#{$i}"]::before,
        .timeline .timeline-item-#{$color}[data-darken-level="#{$i}"]::after {
            background: darken($value, percentage($i/15));
        }
    }
}