.filter-checkbox {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 6px 8px;
  overflow: hidden;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  &:hover {
    background: rgba($purple, 0.1);
    &.checkbox-orange {
      background: rgba($orange, 0.1);
    }
    &.checkbox-lightblue {
      background: rgba($lightblue, 0.1);
    }
  }
  &:active {
    background: rgba($purple, 0.4);
    color: darken($purple, 30%);
    &.checkbox-orange {
      background: rgba($orange, 0.4);
      color: darken($orange, 30%);
    }
    &.checkbox-lightblue {
      background: rgba($lightblue, 0.4);
      color: darken($lightblue, 30%);
    }
  }
  span {
    vertical-align: middle;
    transform: translate3d(0,0,0);
    display: inline-block;
    &:first-child {
      position: relative;
      width: 18px;
      height: 18px;
      transform: scale(1);
      border: 1px solid $gray-500;
      -webkit-transition: all .2s ease;
      transition: all 0.2s ease;
      box-shadow: 0 1px 1px rgba($gray-300, 0.05);
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        -webkit-transition: all .3s 0.1s ease;
        transition: all 0.3s 0.1s ease;
      }
    }
    &:last-child {
      padding-left: 8px;
      -webkit-transition: color .2s ease;
      transition: color 0.2s ease;
    }
  }
  &:hover span:first-child {
    border-color: $purple;
  }

  &.checkbox-orange:hover span:first-child {
    border-color: $orange;
  }
  &.checkbox-lightblue:hover span:first-child {
    border-color: $lightblue;
  }
}

.filter-checkbox-input {
  position: absolute;
  opacity: 0.001;
  &:focus + label {
    background: rgba($purple, 0.1);
    &.checkbox-orange {
      background: rgba($orange, 0.1);
    }
    &.checkbox-lightblue {
      background: rgba($lightblue, 0.1);
    }
  }
  &:checked + .filter-checkbox {
    span {
      &:first-child {
        background: $purple;
        border-color: $purple;
        &::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
      &:last-child {
        color: darken($purple, 30%);
      }
    }
  }
  &.checkbox-orange:checked + .filter-checkbox {
    span {
      &:first-child {
        background: $orange;
        border-color: $orange;
      }
      &:last-child {
        color: darken($orange, 30%);
      }
    }
  }
  &.checkbox-lightblue:checked + .filter-checkbox {
    span {
      &:first-child {
        background: $lightblue;
        border-color: $lightblue;
      }
      &:last-child {
        color: darken($lightblue, 50%);
      }
    }
  }
}

#filter .dropdown-item {
  padding: 0;
  width: auto;
  &:hover {
    background-color: transparent;

  }
}

.type-contain {
  .filter-checkbox {
    color: $white;
    &:hover {
      background: transparent;
      color: lighten($purple, 10%);
    }
  }
  .filter-checkbox-input {
    &:checked + .filter-checkbox {
      span {
        &:last-child {
          color: lighten($purple, 10%);
        }
      }
    }
  }
}
